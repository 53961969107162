export class Day{

    public static DAYS:Day[] = [];

    public static Monday:Day = new Day("Monday", "calendar.header.day.monday", "0 0 0 ? * 2");
    public static Tuesday:Day = new Day("Tuesday", "calendar.header.day.tuesday", "0 0 0 ? * 3");
    public static Wednesday:Day = new Day("Wednesday", "calendar.header.day.wednesday", "0 0 0 ? * 4");
    public static Thursday:Day = new Day("Thursday", "calendar.header.day.thursday", "0 0 0 ? * 5");
    public static Friday:Day = new Day("Friday", "calendar.header.day.friday", "0 0 0 ? * 6");
    public static Saturday:Day = new Day("Saturday", "calendar.header.day.saturday", "0 0 0 ? * 7");
    public static Sunday:Day = new Day("Sunday", "calendar.header.day.sunday", "0 0 0 ? * 1");

    name: string;
    nameToTranslate: string;
    cronstring:string;
    constructor(name:string, nameToTranslate:string, cronstring:string){
        this.name = name;
        this.nameToTranslate = nameToTranslate;
        this.cronstring = cronstring;
        Day.DAYS.push(this);
    }

    //convert day digit to day, e.g. converts 5 to Thursday
	public static getDayForDayCronString(dayCronString:String):Day{
        return Day.DAYS.find(day => day.cronstring.split(" ")[5] === dayCronString);
    }

    public static getDayForDate(date: Date): Day{
      const currentDayIndex = date.getDay() == 0 ? 6 : date.getDay() - 1;
      return Day.DAYS[currentDayIndex];
    }

    public static getDayForIndex(index: number): Day{
        if (index < this.DAYS.length){
            return this.DAYS[index];
        }
        return null;
    }

}

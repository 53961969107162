export interface ImageHolder {
  name: string;
  imageInfo: ImageInfo;
}

export class ImageInfo {

  urlMobileSquare: string;
  urlMobileLandscape: string;
  urlWebSquare: string;
  urlWebLandscape: string;

  constructor(imageInfo?: ImageInfo){
      if (imageInfo){
          this.urlMobileSquare = imageInfo.urlMobileSquare;
          this.urlMobileLandscape = imageInfo.urlMobileLandscape;
          this.urlWebSquare = imageInfo.urlWebSquare;
          this.urlWebLandscape = imageInfo.urlWebLandscape;
      }
  }
}

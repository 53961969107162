import { Component } from '@angular/core';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { DTO_ActiveMode } from '@service/api/zone-configuration-api.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { MusicSelectionService } from '@service/music-selection.service';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { StreamMode } from '@model/enums/streamMode';
import { TranslateService } from '@ngx-translate/core';
import { QueueService } from '@service/queue.service';

@Component({
  selector: 'tun-radio-queue-header',
  templateUrl: './radio-queue-header.component.html',
  styleUrls: ['./radio-queue-header.component.scss']
})
export class RadioQueueHeaderComponent {

  public get hasItems$(){
    return this.queueService.radioQueue$
    .pipe(
      map((queue) => {
        return queue != null && queue.length > 0;
      }));
  }

  public get radioQueueTitle$(){
    return combineLatest(
      [
        this.activeMusicSelectionService.selectedStreamMode$,
        this.activeMusicSelectionService.selectedCalendar$,
        this.activeMusicSelectionService.selectedMusicChannel$,
        this.translateService.stream("radioQueue.queueHeaderLabel.empty"),
        this.translateService.stream("radioQueue.queueHeaderLabel")
      ]
    )
    .pipe(
      map(([streamMode, calendar, musicChannel, emptyTitleString, titleString ]) => {
        if (streamMode && streamMode == StreamMode.CALENDAR && calendar != null) {
          return titleString.replace("{0}", calendar.name)
        }else if (streamMode && streamMode == StreamMode.MUSIC_CHANNEL && musicChannel != null) {
          return titleString.replace("{0}", musicChannel.name)
        }
        return emptyTitleString;
      })
    )
  }

  constructor(
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private musicManipulationService: MusicManipulationService,
    private translateService: TranslateService,
    private queueService: QueueService
  ) {

  }

  public onRefresh(){
    this.musicManipulationService.refreshRadioQueue();
  }
}

<div class="wrapper">

  <!-- header -->
  <div
    class="header-container">

    <div class="header-label">
      {{'settings.blockedSongs.removeAll.confirm.title' | translate }}
    </div>

  </div>

  <div mat-dialog-content class="content-container">
    <div class="text-container">
      {{'settings.blockedSongs.removeAll.confirm.info' | translate }}
    </div>


  </div>
  <div mat-dialog-actions>
    <div class="buttons-container">
      <button class="confirm-button" (click)="onNoClick()" cdkFocusInitial>{{'general.cancel' | translate | uppercase }}</button>
    </div>
    <div class="buttons-right-side">
      <button class="confirm-button" (click)="onYesClick()">{{'settings.blockedSongs.removeAll.confirm.commitButton' | translate | uppercase}}</button>
    </div>
  </div>


</div>


import { Injectable, OnDestroy } from '@angular/core';
import { AudioProperties, PlayableAudio } from '@model/audioFile';
import { PlayState } from '@model/enums/playState.enum';
import { ApplicationMode, ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { PlayTokenService } from '@service/play-token.service';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap, takeUntil, mapTo } from 'rxjs/operators';
import { MusicPlayerService } from '../audio/music-player.service';
import { RemotePlayStateService } from '@service/remote-play-state.service';
import { PlayModeForRemoteSharing, playModeForRemoteSharingToPlayState } from '@service/vo/remote/remote-objects';
import { AudioTagService } from '@service/audio.tag.service';

@Injectable({
  providedIn: 'root'
})
export class PlayingItemService implements OnDestroy{

  constructor(
    private zoneConnectionsService: ZoneConnectionsService,
    private musicPlayerService: MusicPlayerService,
    private remotePlayStateService: RemotePlayStateService,
    private audioTagService: AudioTagService
  ) {
    this.initCurrentTimeSource();
    this.initTotalTimeSource();

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  // MARK: - Service public properties

  //playing audio
  public get playingAudio$(){
     return combineLatest([this.zoneConnectionsService.applicationMode$, this.musicPlayerService.currentActiveAudioFileWithPlayInfo$, this.remotePlayStateService.remoteTrackObject$, this.remotePlayStateService.currentTrack$])
       .pipe(
         map(([applicationMode, currentActiveAudioFileWithPlayInfo, remoteTrackObject, remoteTrack]) => {
           if (applicationMode == ApplicationMode.remoteMode){
            return remoteTrackObject != null ? remoteTrackObject : remoteTrack
           }
           if (currentActiveAudioFileWithPlayInfo){
            return currentActiveAudioFileWithPlayInfo.audioFile
           }
           return null
         })
       )
   }

   //need a 'click' to start playback
   public get needClickToPlay$(){
    return combineLatest([this.zoneConnectionsService.applicationMode$, this.musicPlayerService.currentActiveAudioFileWithPlayInfo$, this.audioTagService.needClickToStartAudio$])
       .pipe(
         map(([applicationMode, currentActiveAudioFileWithPlayInfo, needClickToStartAudio]) => {
          if (applicationMode == ApplicationMode.playerMode){
            return needClickToStartAudio || (currentActiveAudioFileWithPlayInfo != null && currentActiveAudioFileWithPlayInfo.needClickToUseAudioTag)
          }
          return false
         })
       )
   }
   public get needClickToPlay(){
    return this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode
            && (this.audioTagService.needClickToStartAudio
                || (this.musicPlayerService.currentActiveAudioFileWithPlayInfo != null && this.musicPlayerService.currentActiveAudioFileWithPlayInfo.needClickToUseAudioTag));

   }

   public get needClickToPlayAndShouldPlay(){
    return this.needClickToPlay && this.musicPlayerService.currentActiveAudioFileWithPlayInfo != null && (this.musicPlayerService.playState == PlayState.PLAYING || this.musicPlayerService.playState == PlayState.STARTING_TO_PLAY);
   }

   public get needClickToPlayAndShouldPlay$(){
    return combineLatest([this.needClickToPlay$, this.musicPlayerService.currentActiveAudioFileWithPlayInfo$, this.musicPlayerService.playerState$])
    .pipe(
      map(([needClickToPlay, currentActiveAudioFileWithPlayInfo, playerState]) => {
        return needClickToPlay && currentActiveAudioFileWithPlayInfo && (playerState == PlayState.PLAYING || playerState == PlayState.STARTING_TO_PLAY)
      })
    )
   }

  //Playstate
  public get playState(){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.remoteMode){
      return playModeForRemoteSharingToPlayState(this.remotePlayStateService.playMode);
    }
    return this.musicPlayerService.playState;
  }

  public get playState$(){
    return combineLatest([this.zoneConnectionsService.applicationMode$, this.musicPlayerService.playerState$, this.remotePlayStateService.playMode$])
      .pipe(
        map(([applicationMode, playState, remotePlayMode]) => {
          if (applicationMode == ApplicationMode.remoteMode){
            return playModeForRemoteSharingToPlayState(remotePlayMode);
          }
          return playState;
        })
      )
  }

  public get isPlaying$(){
    return this.playState$.pipe(map(playState => {
      return playState == PlayState.PLAYING || playState == PlayState.STARTING_TO_PLAY
    }))
  }

  public get isPlaying(){
    return this.playState == PlayState.PLAYING || this.playState == PlayState.STARTING_TO_PLAY
  }


  //current time

  //A dummy currentTime observable when no current audio is active
  private dummyCurrentTime$ = new BehaviorSubject<number>(0).asObservable();

  private currentTime_source = new BehaviorSubject(this.dummyCurrentTime$);
  private initCurrentTimeSource(){
    combineLatest([this.zoneConnectionsService.applicationMode$, this.musicPlayerService.currentActiveAudioFileWithPlayInfo$])
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      ([applicationMode, currentActiveAudioFileWithPlayInfo]) => {
        if (applicationMode == ApplicationMode.remoteMode){
          this.currentTime_source.next(this.remotePlayStateService.progress$)
        }else{
          if (currentActiveAudioFileWithPlayInfo != null){
            this.currentTime_source.next(currentActiveAudioFileWithPlayInfo.currentTime$)
          }else{
            this.currentTime_source.next(this.dummyCurrentTime$)
          }
        }
      }
    );
  }

  public get currentTimeInSeconds$(){
    return this.currentTime_source.pipe(switchMap(source => source));
  }

  public get currentTimeInSeconds(){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.remoteMode){
      return this.remotePlayStateService.progress;
    }else{
      if (this.musicPlayerService.currentActiveAudioFileWithPlayInfo != null){
        return this.musicPlayerService.currentActiveAudioFileWithPlayInfo.currentTime;
      }
    }
    return 0;
  }


  //total time

  //A dummy totalTime observable when no current audio is active
  private dummyTotalTime$ = new BehaviorSubject<number>(0).asObservable();

  private totalTime_source = new BehaviorSubject(this.dummyTotalTime$);
  private initTotalTimeSource(){
    combineLatest([this.zoneConnectionsService.applicationMode$, this.musicPlayerService.currentActiveAudioFileWithPlayInfo$])
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      ([applicationMode, currentActiveAudioFileWithPlayInfo]) => {
        if (applicationMode == ApplicationMode.remoteMode){
          this.totalTime_source.next(this.remotePlayStateService.totalTime$)
        }else{
          if (currentActiveAudioFileWithPlayInfo != null){
            this.totalTime_source.next(currentActiveAudioFileWithPlayInfo.duration$)
          }else{
            this.totalTime_source.next(this.dummyTotalTime$)
          }
        }
      }
    );
  }

  public get totalTimeInSeconds$(){
    return this.totalTime_source.pipe(switchMap(source => source));
  }

  public get totalTimeInSeconds(){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.remoteMode){
      return this.remotePlayStateService.totalTime;
    }else{
      if (this.musicPlayerService.currentActiveAudioFileWithPlayInfo != null){
        return this.musicPlayerService.currentActiveAudioFileWithPlayInfo.duration;
      }
    }
    return 0;
  }


}

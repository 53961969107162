<div #wrapper class="wrapper" (click)="onShowDetails()">

  <div class="image-wrapper">

    <div class="image-placeholder {{(isActive$ | async) ? 'active' : ''}}">
      <tun-image-on-screen-loader class="image" [src]="imageUrl"></tun-image-on-screen-loader>


      <div *ngIf="playButtonShowStragegy != PlayButtonShowStrategy.NEVER && (mouseOver || (forceShowTogglePlayButton$ | async))" class="playButtonContainer {{(playButtonShowStragegy != PlayButtonShowStrategy.NEVER) ? 'showOnHover' : '' }} {{(forceShowTogglePlayButton$ | async) ? 'forceShow': ''}}">
        <tun-play-music-selection-button
          class="play-button"
          [itemToStart]="startableItem"
          [forcePlayable]="true"
          (itemIsStarting)="onItemIsStarting()"
        >
        </tun-play-music-selection-button>
      </div>

    </div>

  </div>


  <div class="titleContainer">
    <tun-music-play-animation
      *ngIf="showPlayAnimation$ | async"
      [playing]="animatePlayAnimation$ | async"
      [musicPlayAnimationColors]="playAnimationColors"
    >
    </tun-music-play-animation>
    <tun-scrollable-text *ngIf="imageHolder != null" class="title" [text]="imageHolder.name"></tun-scrollable-text>
  </div>


  <div *ngIf="recommendationsAmount > 0" class="badgeContainer">
    <div class="badge">
      {{recommendationsAmount}}
    </div>
  </div>


</div>



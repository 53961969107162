import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackViewComponent } from './track-view/track-view.component';
import { IconsV5Module } from '@components/icons-v5/icons-v5.module';
import { ScrollableTextComponent } from './scrollable-text/scrollable-text.component';
import { TrackDraggingViewComponent } from './track-dragging-view/track-dragging-view.component';
import { PlayMusicSelectionButtonComponent } from './play-music-selection-button/play-music-selection-button.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderV5Component } from './loader-v5/loader-v5.component';
import { TranslateModule } from '@ngx-translate/core';
import { TrackListViewComponent } from './track-list-view/track-list-view.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CheckboxV5Component } from './checkbox-v5/checkbox-v5.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChangeableParametersPanelV5Component } from './changeable-parameters-panel-v5/changeable-parameters-panel-v5.component';
import { BaseSliderV5Component } from './sliders/base-slider-v5/base-slider-v5.component';
import { TextSliderV5Component } from './sliders/text-slider-v5/text-slider-v5.component';
import { MapSliderOptionsV5 } from './sliders/map-slider-options-v5.pipe';
import { FlexibleSliderV5Component } from './sliders/flexible-slider-v5/flexible-slider-v5.component';
import { ResizeModule } from '@components/resize/resize.module';
import { TweakStrengthBarV5Component } from './tweak-strength-bar-v5/tweak-strength-bar-v5.component';
import { MusicChannelViewComponent } from './music-channel-group-view/music-channel-view/music-channel-view.component';
import { MusicChannelGroupViewComponent } from './music-channel-group-view/music-channel-group-view.component';
import { ImageOnScreenLoaderComponent } from './image-on-screen-loader/image-on-screen-loader.component';
import { AppComponentsModule } from '@components/app-components/app-components.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MusicPlayAnimationComponent } from './music-play-animation/music-play-animation.component';
import { CalendarFromImageHolderViewComponent, ImageHolderViewComponent, MusicChannelFromImageHolderViewComponent } from './image-holder-view/image-holder-view.component';



@NgModule({
  declarations: [
    TrackViewComponent,
    TrackDraggingViewComponent,
    LoaderV5Component,
    TrackListViewComponent,
    CheckboxV5Component,
    ChangeableParametersPanelV5Component,
    BaseSliderV5Component,
    TextSliderV5Component,
    MapSliderOptionsV5,
    FlexibleSliderV5Component,
    TweakStrengthBarV5Component,
    MusicChannelGroupViewComponent,
    MusicChannelViewComponent,
    MusicPlayAnimationComponent,
    ImageHolderViewComponent,
    MusicChannelFromImageHolderViewComponent,
    CalendarFromImageHolderViewComponent
  ],
  imports: [
    CommonModule,
    IconsV5Module,
    MatProgressSpinnerModule,
    TranslateModule,
    ScrollingModule,
    DragDropModule,
    MatCheckboxModule,
    ResizeModule,
    AppComponentsModule,
    MatBadgeModule,
    ScrollableTextComponent,
    ImageOnScreenLoaderComponent,
    PlayMusicSelectionButtonComponent,
  ],
  exports: [
    TrackViewComponent,
    TrackDraggingViewComponent,
    LoaderV5Component,
    TrackListViewComponent,
    CheckboxV5Component,
    ChangeableParametersPanelV5Component,
    TweakStrengthBarV5Component,
    MusicChannelGroupViewComponent,
    MusicChannelViewComponent,
    MusicPlayAnimationComponent,
    MusicChannelFromImageHolderViewComponent,
    CalendarFromImageHolderViewComponent
  ]
})
export class ComponentsV5Module { }

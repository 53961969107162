import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { TunifyColor } from '../../../model/enums/tunifyColor.enum';
import { AudioFileProperty, formatTrackProperty } from '@model/enums/audioFileProperty';
import { CheckBoxColor } from '@model/fieldModels/checkboxColor';
import { TunifyCheckBoxColor } from '../checkbox-v5/checkbox-v5.component';

@Component({
  selector: 'tun-track-view',
  templateUrl: './track-view.component.html',
  styleUrls: ['./track-view.component.scss']
})
export class TrackViewComponent {

  @Input() track: AudioFile
  @Input() tunifyColor: TunifyColor

  @Input() playEnabled = true;

  @Input() useSelectionBox: boolean = false;
  @Input() showSelectionBox: boolean = false;
  @Input() isSelected: boolean = false;

  @Input() showAddButton: boolean = false;

  @Input() showAudioFileProperty: AudioFileProperty

  @Output() play = new EventEmitter<void>()
  @Output() showOptions = new EventEmitter<void>()
  @Output() checkedChanged = new EventEmitter()

  public TunifyColor = TunifyColor

  public get checkBoxColor(){
    if (this.tunifyColor == TunifyColor.ORANGE){
      return TunifyCheckBoxColor.ORANGE
    }else if (this.tunifyColor == TunifyColor.BLUE){
      return TunifyCheckBoxColor.BLUE
    }else if (this.tunifyColor == TunifyColor.GREEN){
      return TunifyCheckBoxColor.GREEN
    }
    return TunifyCheckBoxColor.WHITE
  }

  public get trackPropertyText(){
    if (this.track && this.showAudioFileProperty){
      return formatTrackProperty(this.track, this.showAudioFileProperty);
    }
    return ""
  }

  public onPlay(){
    this.play.emit();
  }

  public onOptions(){
    this.showOptions.emit();
  }

  public onAdd(){
    this.checkedChanged.emit(true);
  }

  public onCheckedChange(value:boolean){
    this.checkedChanged.emit(value);
  }

  public onDoubleClick(){
    this.play.emit();
  }


}

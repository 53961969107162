import { Injectable } from '@angular/core';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiSettingsService {

  public get isNewInterface$(){
    return this.zoneConnectionsService.activeZoneConnection$
        .pipe(
            map(zoneConnection => zoneConnection != null && (zoneConnection.zoneId > 543850 || zoneConnection.zoneId == 331412))
        )
      }

  constructor(
    private zoneConnectionsService: ZoneConnectionsService
  ) {

  }
}

<div (tunResize)="onResize($event)" class="wrapper {{(hasSomethingToPlay$ | async) ? 'enabled':'disabled'}}">

  <div class="title">{{'queue.label.title' | translate}}</div>

  <tun-waiting-queue-header class="queueHeader"
    [open]="open$ | async" (toggleOpen)="onToggleOpen()"
    (shuffle)="onShuffle()"
    (clear)="onClear()"
  >
  </tun-waiting-queue-header>


    <!-- cdkDropListAutoScrollDisabled does not work, because the drag&drop library assumes all items are always added to the dom -->

    <cdk-virtual-scroll-viewport
      #viewport
      class="queueTable"
      id="queue"
      [itemSize]="virtualScrollingItemSize$ | async"
      cdkDropList
      #queue="cdkDropList"
      [cdkDropListData]="queueTableItems"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListSortPredicate]="radioHeaderPredicate"
      [cdkDropListConnectedTo]="['queueHeader']"
      [cdkDropListAutoScrollDisabled]="disableAutoScroll$ | async"
    >
      <!--
      ---- templateCacheSize: 0
      ---- We can not use a cache to support drag & drop through the angular cdk
      ---- Drag & drop caches items indexes, this cache is not re-calculated if an item moves from on-screen to the cache
      -->

        <ng-container
          *cdkVirtualFor="
            let tableItem of queueTableItems;
            let i = index;
            templateCacheSize: 0
          "
        >

          <tun-track-view *ngIf="tableItem.type == TrackTableItemType.WAITING_QUEUE_ITEM" class="trackItem"
            cdkDrag
            [cdkDragData]="tableItem"
            [track]="tableItem.track"
            [playEnabled]="startTrackEnabled$ | async"
            tunifyColor="TunifyColor.orange"
            (play)="onPlay(tableItem.track)"
            (showOptions)="onShowOptions(tableItem.track, TrackInfoContextMode.queue)"
            [style.height.px]="itemSize$ | async"
          >

            <!--
              We can not use a custom dragPreview
              The dragpreview is inserted at the 0,0 position of the mouse cursor when dragging starts, this feels weird
            -->

            <!--
            <tun-track-view  class="trackItem"
              *cdkDragPreview
              [track]="tableItem.track"
            >
            </tun-track-view >
            -->

            <!--
            <div *cdkDragPreview>{{ tableItem.track.title }}</div>
            -->
          </tun-track-view>

          <tun-radio-queue-header *ngIf="tableItem.type == TrackTableItemType.RADIO_QUEUE_HEADER" class="trackItem"
            cdkDrag
            cdkDragDisabled
            [style.height.px]="itemSize$ | async"
          >
          </tun-radio-queue-header>

          <tun-track-view *ngIf="tableItem.type == TrackTableItemType.RADIO_QUEUE_ITEM" class="trackItem"
            cdkDrag
            [cdkDragData]="tableItem"
            [track]="tableItem.track"
            [tunifyColor]="radioQueueTunifyColor$ | async"
            [playEnabled]="startTrackEnabled$ | async"
            (play)="onPlay(tableItem.track)"
            (showOptions)="onShowOptions(tableItem.track, TrackInfoContextMode.radioQueue)"
            [style.height.px]="itemSize$ | async"
          >
          </tun-track-view>



        </ng-container>

    </cdk-virtual-scroll-viewport>



</div>

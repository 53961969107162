import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, merge } from 'rxjs';
import { PlayingItemService } from './playing-item.service';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { MusicChannel } from '@model/musicChannel';
import { MusicCollection } from '@model/musicCollection';
import { Calendar } from '@model/calendar';
import { CalendarItem } from '@model/calendarItem';
import { takeUntil, filter, map, switchMap } from 'rxjs/operators';
import { CalendarGroupService } from '@service/calendar-group.service';
import { MusicChannelGroup } from '@model/musicChannelGroup';
import { MusicChannelService } from '@service/music-channel.service';
import { AudioFile, QueueOrigin } from '@model/audioFile';
import { DTO_TrackOrigin } from '@service/api/dto/trackOrigin';
import { AudioFileOriginType } from '@model/enums/audioFileOriginType';
import { LoggerService } from '@service/loggers/logger.service';
import { CalendarService } from '@service/calendar.service';
import { custom_calendars } from '../data/mock/calendars/customCalendars';
import { StreamMode } from '@model/enums/streamMode';

export class MusicSelectionState{
  public static PLAYINFO_DEFAULT = "assets/icons/playinfo_default.png";

  public origin: PlayingOrigin;

  public musicChannel : MusicChannel;
  public musicCollection: MusicCollection;

  public calendar: Calendar;
  public calendarItem: CalendarItem;

  public get imageUrl(){
    if (this.origin == PlayingOrigin.musicChannel && this.musicChannel != null && this.musicChannel.imageInfo != null && this.musicChannel.imageInfo.urlWebLandscape != null){
      return this.musicChannel.imageInfo.urlWebLandscape
    }else if (this.origin == PlayingOrigin.calendar && this.calendar != null && this.calendar.imageInfo != null && this.calendar.imageInfo.urlWebLandscape != null){
      return this.calendar.imageInfo.urlWebLandscape
    }else if (this.origin == PlayingOrigin.none){
      return null;
    }
    return MusicSelectionState.PLAYINFO_DEFAULT;
  }

  constructor(origin: PlayingOrigin = PlayingOrigin.none){
   this.origin = origin
  }

  public equals(state: MusicSelectionState): boolean{
    return this.origin == state.origin && this.musicChannel == state.musicChannel && this.musicCollection == state.musicCollection && this.calendar == state.calendar && this.calendarItem == state.calendarItem
  }
}

export enum PlayingOrigin{
  none = "none",
  waitingQueue = "waitingQueue",
  message = "message",
  calendar = "calendar",
  musicChannel = "musicChannel"
}

@Injectable({
  providedIn: 'root'
})
export class PlayingMusicSelectionService implements OnDestroy{

  private LOGGER_CLASSNAME = 'PlayingMusicSelectionService';

  private get _playingMusicSelection(): MusicSelectionState {
    return this._playingMusicSelectionSubject.value;
  }
  private set _playingMusicSelection(value: MusicSelectionState) {
    if (!this._playingMusicSelection.equals(value)) {
      this._playingMusicSelectionSubject.next(value);
      if (value.origin != PlayingOrigin.none){
        this._lastRealplayingMusicSelection = value;
      }
    }
  }
  get playingMusicSelection(): MusicSelectionState {
    return this._playingMusicSelection;
  }
  private _playingMusicSelectionSubject = new BehaviorSubject<MusicSelectionState>(new MusicSelectionState());
  public playingMusicSelection$ = this._playingMusicSelectionSubject.asObservable();


  //Keep track of the last real origin -> this is to avoid having no origin when we are in remote mode and the info is still comming in
  private get _lastRealplayingMusicSelection(): MusicSelectionState {
    return this._lastRealplayingMusicSelectionSubject.value;
  }
  private set _lastRealplayingMusicSelection(value: MusicSelectionState) {
    if (!this._lastRealplayingMusicSelection.equals(value)) {
      this._lastRealplayingMusicSelectionSubject.next(value);
    }
  }
  get lastRealplayingMusicSelection(): MusicSelectionState {
    return this._lastRealplayingMusicSelection;
  }
  private _lastRealplayingMusicSelectionSubject = new BehaviorSubject<MusicSelectionState>(new MusicSelectionState());
  public lastRealplayingMusicSelection$ = this._lastRealplayingMusicSelectionSubject.asObservable();


  constructor(
    private playingItemService: PlayingItemService,
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private musicChannelService: MusicChannelService,
    private calendarGroupService: CalendarGroupService,
    private calendarService: CalendarService,
    private loggerService: LoggerService
  ) {

    this.initCalendarItemsLoadingDone();

    combineLatest([
      this.activeMusicSelectionService.selectedStreamMode$,
      this.activeMusicSelectionService.selectedCalendar$
    ])
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      ([
        selectedStreamMode,
        selectedCalendar
      ]) => {
        if (selectedStreamMode == StreamMode.CALENDAR && selectedCalendar != null){
          if (!selectedCalendar.calendarItemsLoaded && !selectedCalendar.calendarItemsLoading){
            this.calendarService.loadCalendarItems(selectedCalendar);
          }
        }
      }
    )

    //TODO ->
    // fetch calendarItems and watch changes

    //we can only combine up to 6 streams, so we need to nest them
    combineLatest(
      [combineLatest([
        this.playingItemService.playingAudio$,
        this.activeMusicSelectionService.selectedStreamMode$,
        this.activeMusicSelectionService.selectedMusicChannel$,
        this.activeMusicSelectionService.selectedCalendar$,
        this.calendarItemsLoadingDoneToggler$
      ]),
      combineLatest([
        this.musicChannelService.musicChannelGroups$,
        this.calendarGroupService.calendarGroups$,
        this.calendarService.customCalendars$
      ])
    ])
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      ([
        [playingAudio,
        selectedStreamMode,
        selectedMusicChannel,
        selectedCalendar,
        loadingDonToggler],
      [
        musicChannelGroups,
        calendarGroups,
        customCalendars
        ]
      ])=>{
        let currentPlayingOrigin = PlayingOrigin.none;

        let currentPlayingMusicChannel: MusicChannel;
        let currentPlayingMusicCollection: MusicCollection;
        let currentPlayingCalendar: Calendar;
        let currentPlayingCalendarItem: CalendarItem;

        if (playingAudio instanceof AudioFile){
          const audioFile = playingAudio as AudioFile;
          if (audioFile.queueOrigin == QueueOrigin.radio || audioFile.queueOrigin == QueueOrigin.remote){
            const origin = audioFile.origin
            if (origin == null){
              currentPlayingOrigin = PlayingOrigin.none;
            }else{
              if (origin.type == AudioFileOriginType.MUSICCOLLECTION){
                const idValue = parseInt(origin.data, 10);
                if ( !isNaN(idValue)) {
                  const activeMusicCollectionId = idValue;

                  if (selectedMusicChannel != null && selectedMusicChannel.musicCollections != null){
                    const activeMusicCollection = selectedMusicChannel.musicCollections.find((mc) => mc.id == activeMusicCollectionId);
                    if (activeMusicCollection != null){
                      currentPlayingOrigin = PlayingOrigin.musicChannel
                      currentPlayingMusicChannel = selectedMusicChannel
                      currentPlayingMusicCollection = activeMusicCollection
                    }
                  }

                  if (currentPlayingOrigin == PlayingOrigin.none && musicChannelGroups != null){
                    let groupIndex = 0
                    while (currentPlayingMusicChannel == null && groupIndex < musicChannelGroups.length){
                      const musicChannelGroup = musicChannelGroups[groupIndex];
                      const musicChannels = musicChannelGroup.musicChannels;
                      if (musicChannels != null){
                        let index = 0;
                        while (currentPlayingMusicChannel == null && index < musicChannels.length){
                          const musicChannel = musicChannels[index];
                          if (musicChannel.musicCollections != null){
                            const musicCollection = musicChannel.musicCollections.find((mc) =>  mc.id == activeMusicCollectionId);
                            if (musicCollection != null){
                              currentPlayingOrigin = PlayingOrigin.musicChannel
                              currentPlayingMusicChannel = musicChannel
                              currentPlayingMusicCollection = musicCollection
                            }
                          }

                          index += 1
                        }
                      }

                      groupIndex += 1
                    }

                    //only set to search when our musicChannelGroups are loaded
                    if (currentPlayingOrigin == PlayingOrigin.none) {
                      currentPlayingOrigin = PlayingOrigin.waitingQueue
                    }
                  }

                } else {
                  this.loggerService.error(this.LOGGER_CLASSNAME, "stateChange", "Could not parse musicCollection id from origin data " + origin.data);
                }
              }else if (origin.type == AudioFileOriginType.CALENDAR_ITEM){
                currentPlayingOrigin = PlayingOrigin.calendar;

                const idValue = parseInt(origin.data, 10);
                if ( !isNaN(idValue)) {
                  const activeCalendarItemId = idValue;

                  if (selectedCalendar != null && selectedCalendar.calendarItems != null){
                    const playingCalendarItems = selectedCalendar.calendarItems.filter($0 => $0.id == activeCalendarItemId)
                        if (playingCalendarItems.length > 0){
                          currentPlayingCalendar = selectedCalendar
                          currentPlayingCalendarItem = playingCalendarItems[0]
                        }
                  }

                  if (currentPlayingCalendar == null && customCalendars != null){
                    let calendarIndex = 0
                    while (currentPlayingCalendar == null && calendarIndex < customCalendars.length){
                      const calendarToCheck = customCalendars[calendarIndex];
                      if (calendarToCheck.calendarItems != null){
                        const playingCalendarItems = calendarToCheck.calendarItems.filter($0 => $0.id == activeCalendarItemId)
                        if (playingCalendarItems.length > 0){
                          currentPlayingCalendar = calendarToCheck
                          currentPlayingCalendarItem = playingCalendarItems[0]
                        }
                      }
                      calendarIndex++
                    }
                  }

                  if (currentPlayingCalendar == null && calendarGroups != null){
                    calendarGroups.forEach(calendarGroup => {

                      let calendarIndex = 0
                      while (currentPlayingCalendar == null && calendarIndex < calendarGroup.calendars.length){
                        const calendarToCheck = calendarGroup.calendars[calendarIndex];
                        if (calendarToCheck.calendarItems != null){
                          const playingCalendarItems = calendarToCheck.calendarItems.filter($0 => $0.id == activeCalendarItemId)
                          if (playingCalendarItems.length > 0){
                            currentPlayingCalendar = calendarToCheck
                            currentPlayingCalendarItem = playingCalendarItems[0]
                          }
                        }
                        calendarIndex++
                      }
                    })

                  }
                }

              }else if (origin.type == AudioFileOriginType.SEARCH_AUTOCOMPLETION_ON_GROUP
                || origin.type == AudioFileOriginType.SEARCH_AUTOCOMPLETION_ON_SONG
              || origin.type == AudioFileOriginType.SEARCH_BY_VALUE
              || origin.type == AudioFileOriginType.SEARCH_ON_GROUP
              || origin.type == AudioFileOriginType.SEARCH_ON_TITLE
              || origin.type == AudioFileOriginType.SUGGESTION_BPM
              || origin.type == AudioFileOriginType.SUGGESTION_MOOD
              || origin.type == AudioFileOriginType.SUGGESTION_YEAR
              || origin.type == AudioFileOriginType.SUGGESTION_STYLE
              || origin.type == AudioFileOriginType.SUGGESTION_SIMILAR
              || origin.type == AudioFileOriginType.SUGGESTION_DANCING_STYLE){
                currentPlayingOrigin = PlayingOrigin.waitingQueue; //this is actually a search
              }else if (origin.type == AudioFileOriginType.BRAND_AUDIO_MESSAGE){
                currentPlayingOrigin = PlayingOrigin.message;
              }
            }
          }else{
            if (audioFile.origin && audioFile.origin.type == AudioFileOriginType.BRAND_AUDIO_MESSAGE){
              currentPlayingOrigin = PlayingOrigin.message;
            }else{
              currentPlayingOrigin = PlayingOrigin.waitingQueue;
            }

          }
        }

        const musicSelectionState = new MusicSelectionState(currentPlayingOrigin);
        musicSelectionState.musicChannel = currentPlayingMusicChannel;
        musicSelectionState.musicCollection = currentPlayingMusicCollection;
        musicSelectionState.calendar = currentPlayingCalendar;
        musicSelectionState.calendarItem = currentPlayingCalendarItem;

        this._playingMusicSelection = musicSelectionState;
      }
    )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }


//A loading done observable - toggled true/false each time the current calendar has done loading its calendarItems
private calendarItemsLoadingDoneTogglerSubject = new BehaviorSubject<boolean>(false);
private calendarItemsLoadingDoneToggler$ = this.calendarItemsLoadingDoneTogglerSubject.asObservable();

private initCalendarItemsLoadingDone(){
  combineLatest([
    this.activeMusicSelectionService.selectedStreamMode$,
    this.activeMusicSelectionService.selectedCalendar$
  ])
  .pipe(
    takeUntil(
      this.destroyed$
    )
  )
  .subscribe(
    ([selectedStreamMode, selectedCalendar]) => {
      if (selectedStreamMode == StreamMode.CALENDAR){
        this.watchCalendarItemsOfCalendar = selectedCalendar
      }else{
        this.watchCalendarItemsOfCalendar = null
      }
    }
  );
}


private _watchCalendarItemsOfCalendar: Calendar
private watchingCalendarChangedSubject$ = new Subject<void>();
private set watchCalendarItemsOfCalendar(value: Calendar){
  this._watchCalendarItemsOfCalendar = value
  this.watchingCalendarChangedSubject$.next(null);
  if (this._watchCalendarItemsOfCalendar){
    this.watchCalendarItemsOfCalendar.calendarItemsLoadingDone$
    .pipe(
      takeUntil(
        merge(
          this.watchingCalendarChangedSubject$,
          this.destroyed$
        )
      )
    )
    .subscribe(
      () => {
        this.calendarItemsLoadingDoneTogglerSubject.next(!this.calendarItemsLoadingDoneTogglerSubject.value);
      }
    )
  }
}
private get watchCalendarItemsOfCalendar():Calendar{
  return this._watchCalendarItemsOfCalendar;
}

}

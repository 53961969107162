<div class="wrapper {{tunifyColor == TunifyColor.BLUE ? 'blue' : (tunifyColor == TunifyColor.GREEN ? 'green' : 'orange')}}">

  <div *ngIf="useSelectionBox" class="checkbox-container">
    <tun-checkbox-v5
      class="checkbox {{showSelectionBox ? 'checkbox-force-visible' : ''}}"
      [checkBoxColor]="checkBoxColor"
      [isChecked]="isSelected"
      (isCheckedChanged)="onCheckedChange($event)"
    >
    </tun-checkbox-v5>
  </div>

  <button *ngIf="!showAddButton" class="icon-button {{playEnabled ? '' : 'disable-play'}}" (click)="onPlay()">
    <tun-play-track class="icon {{!showSelectionBox ? 'colored-icon' : ''}}"></tun-play-track>
  </button>

  <button *ngIf="showAddButton" [disabled]="isSelected" class="icon-button" (click)="onAdd()">
    <tun-plus-round-icon-v5 *ngIf="!isSelected" class="icon icon-small {{!showSelectionBox ? 'colored-icon' : ''}}"></tun-plus-round-icon-v5>
    <tun-checkmark-icon-v5 *ngIf="isSelected" class="icon icon-small {{!showSelectionBox ? 'colored-icon' : ''}}"></tun-checkmark-icon-v5>
  </button>

  <div class="trackContent" (dblclick)="onDoubleClick()">
    <div class="trackTitleContainer">
      <tun-scrollable-text class="trackTitle" [text]="track.title"></tun-scrollable-text>
    </div>
    <div class="trackGroupContainer">
      <tun-scrollable-text class="trackGroup" [text]="track.group"></tun-scrollable-text>
    </div>
  </div>

  <div *ngIf="showAudioFileProperty != null" class="trackPropertyContent" (dblclick)="onDoubleClick()">
    <tun-scrollable-text class="trackProperty" [text]="trackPropertyText"></tun-scrollable-text>
  </div>

  <button class="icon-button" (click)="onOptions()">
    <tun-track-options class="icon"></tun-track-options>
  </button>

</div>

import { CalendarItem } from './calendarItem';
import { CalendarType } from './enums/calendarType';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Day } from './day';
import { ImageHolder, ImageInfo } from './imageInfo';

export class Calendar implements ImageHolder{
  calendarId: number;
  name: string;
  imageInfo: ImageInfo;
  calendarGroupId: number;
  isPublished: boolean;
  calendarItems: CalendarItem[];
  type: CalendarType;
  isVisible: boolean;
  timestamp: number;

  calendarItemsLoading: boolean = false;
  calendarItemsLoaded: boolean = false;
  calendarItemsLoadingError: string;

  public refetchNeededForConflict = false; //on a conflict -> we need to refetch even if we don't know the correct timestamp
  public timestampFromUpdateTrigger: number = undefined; //timestamp that we have received after a save or update event. This is used saving is done to check if we have the most recent version
  public refetchedToAvoidVersionConflict = false; //boolean to avoid we fetch multiple times in a row (avoid endless loop when the server messes up the version numbers)


  //adjustments on the calendar object itself (title etc)
  creating = false;
  creatingCopy = false;
  saving = false;
  deleting = false;
  undoingDelete = false;

  private deletedSubject = new BehaviorSubject<boolean>(false);
  public deleted$ = this.deletedSubject.asObservable();
  public get deleted(){
    return this.deletedSubject.value;
  }
  public set deleted(value: boolean){
    this.deletedSubject.next(value);
  }

  //bulk adjustments on the calendarItem objects
  clearingDays: Day[] = [];
  copyingDay: Day;

  clearingDay(day: Day) {
    return this.clearingDays && this.clearingDays.indexOf(day) >= 0;
  }


  //events when loading the calendarItems is done, with a boolean indicating a success (true = success, false = failure)
  private calendarItemsLoadingDoneSource = new Subject<boolean>();
  calendarItemsLoadingDone$ = this.calendarItemsLoadingDoneSource.asObservable();
  public emitCalendarItemsLoadingDone(value: boolean) {
    this.calendarItemsLoadingDoneSource.next(value);
  }


  //An event emitter when the calendar items have been reset.
  //We need this to trigger a reload of the items for the calendar that is currently in the view
  private _calendarItemsResetDoneSubject = new Subject<void>();
  public calendarItemsResetDoneEvent$ = this._calendarItemsResetDoneSubject.asObservable();
  public invalidateCalendarItems() {
    this.calendarItemsLoaded = false;
    this.calendarItemsLoaded = false;
    this.calendarItems = null;
    this.calendarItemsLoadingError = null;
    this._calendarItemsResetDoneSubject.next(null);
  }

  constructor(calendar?: Calendar) {
    if (calendar) {
      this.calendarId = calendar.calendarId;
      this.name = calendar.name;

      this.calendarGroupId = calendar.calendarGroupId;
      this.isPublished = calendar.isPublished;
      this.type = calendar.type;
      this.isVisible = calendar.isVisible;
      this.timestamp = calendar.timestamp;

      if (calendar.calendarItems) {
        this.calendarItems = [];
        calendar.calendarItems.map(calendarItem => this.calendarItems.push(new CalendarItem(calendarItem)));
      }

      if (calendar.imageInfo){
        this.imageInfo = new ImageInfo(calendar.imageInfo);
      }
    }
  }

  public tranferUpToDateDetails(otherCalendar: Calendar){
    if (otherCalendar.calendarItemsLoaded && otherCalendar.calendarId == this.calendarId && otherCalendar.timestamp >= this.timestamp){
      this.calendarItems = otherCalendar.calendarItems;
      this.calendarItemsLoaded = true;
    }
  }

  public determineActiveCalendarItemsAtTime(day: Day, date: Date): CalendarItem[] {
    if (day && date && this.calendarItems){
      const hours = date.getHours();
      const minutes = date.getMinutes();

      const time = hours + minutes / 60;
      return this.calendarItems.filter(calendarItem => {
        const calendarItemStartTime = calendarItem.startHour + calendarItem.startMinutes / 60
        return calendarItem.day == day && time >= calendarItemStartTime && time < calendarItemStartTime + calendarItem.duration / 60
      })
    }

    return [];
  }

}


export function isCalendarEditable(calendar: Calendar): boolean {
  return calendar != null && calendar.type === CalendarType.CUSTOM;
}


<div class="wrapper"
  #container
  (tunResize)="onResizeContainer($event)"
  >

  <div class="imageWrapper">
    <img class="backgroundImage" [src]="imageUrl$ | async">
    <div class="gradientLayer"></div>
  </div>

  <div class="contentWrapper">

    <div class="title">{{'home.continuePlay.label.title' | translate }}</div>
    <hr class="horizontalLine purple-line"/>

    <div class="content-container">

      <div *ngIf="(lastPlayedItemsLoading$ | async)" class="loaderContainer">
        <mat-spinner class="loading-spinner"></mat-spinner>
        <span class="loadingText">{{'general.status.loading' | translate}}</span>
      </div>

      <div *ngIf="(lastPlayedItemsLoadingError$ | async)" class="errorContainer">
        <p>{{'general.status.error.message' | translate}}</p>
        <button (click)="onRetryLastPlayed()" class="retryButton">
          <p>
            {{'general.status.error.retry' | translate }}
          </p>
        </button>
      </div>

      <div *ngIf="showNoLastPlayedItems$ | async" class="noItemsContainer">
        <div class="noItemTitle" translate>home.continuePlay.noContent.title</div>
        <div class="noItemText" translate>home.continuePlay.noContent.text</div>
      </div>

      <div *ngIf="(showLastPlayedItems$ | async)" class="itemContainer {{ gridClassName }}">

          <ng-container *ngFor="let lastPlayedItem of (lastPlayedItems$ | async); index as i">

            <tun-music-channel-view
              *ngIf="isMusicChannel(lastPlayedItem)"
              class="item"
              [imageHolder]="lastPlayedItem.matchedItem"
              playButtonShowStragegy="ALWAYS"
              (showDetails)="onShowDetailsForMusicChannel($event)">
            </tun-music-channel-view>

            <tun-calendar-view
              *ngIf="isCalendar(lastPlayedItem)"
              class="item"
              [imageHolder]="lastPlayedItem.matchedItem"
              playButtonShowStragegy="ALWAYS"
              (showDetails)="onShowDetailsForCalendar($event)">

            </tun-calendar-view>
            <div
              *ngIf="!isMusicChannel(lastPlayedItem) && !isCalendar(lastPlayedItem)"
              class="item emptyItem"
            >

            </div>

          </ng-container>

      </div>

    </div>




    <div class="title">
      <tun-ai-stars-v5 class="icon icon-scaled-svg-button ai-icon"></tun-ai-stars-v5>
      {{'home.recommendations.label.title' | translate }}
      <div class="ai-box">AI</div>
    </div>
    <hr class="horizontalLine green-line"/>
    <div class="content-container">

      <div *ngIf="(recommendationsLoading$ | async)" class="loaderContainer">
        <mat-spinner class="loading-spinner"></mat-spinner>
        <span class="loadingText">{{'general.status.loading' | translate}}</span>
      </div>

      <div *ngIf="(recommendationsLoadingError$ | async)" class="errorContainer">
        <p>{{'general.status.error.message' | translate}}</p>
        <button (click)="onRetryRecommendations()" class="retryButton">
          <p>
            {{'general.status.error.retry' | translate }}
          </p>
        </button>
      </div>

      <div *ngIf="showNoRecommendations$ | async" class="noItemsContainer">
        <div class="noItemTitle" translate>home.recommendations.noContent.title</div>
        <div class="noItemText" translate>home.recommendations.noContent.text</div>
      </div>


      <div *ngIf="(showRecommendations$ | async)" class="itemContainer {{ gridClassName }}">
        <ng-container *ngFor="let recommendedMusicChannel of (recommendedMusicChannels$ | async); index as i">
          <tun-music-channel-view
            class="item"
            [imageHolder]="recommendedMusicChannel.musicChannel"
            [recommendationsAmount]="recommendedMusicChannel.musicCollections.length"
            (showDetails)="onShowDetailsForMusicChannel($event)">
          </tun-music-channel-view>
        </ng-container>

      </div>
    </div>

  </div>



</div>

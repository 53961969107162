import { Injectable } from '@angular/core';
import { MusicChannelService } from '@service/music-channel.service';
import { CalendarService } from '@service/calendar.service';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarGroupService } from '@service/calendar-group.service';
import { AuthenticationService } from '@service/authentication.service';
import { MusicChannel } from '@model/musicChannel';
import { Calendar } from '@model/calendar';
import { LoggerService } from '@service/loggers/logger.service';
import { DTO_MusicSelection, DTO_ActiveMode } from '@service/api/zone-configuration-api.service';
import { StreamMode } from '@model/enums/streamMode';
import { MusicSelectionService } from '../data/music-selection.service';

/**
 * The activeMusicSelection service keeps track of
 * - the selected stream mode (BLUE / GREEN)
 * - the selected musicChannel
 * - the selected calendar
 *
 * It tracks the configured id's from the zoneConfiguration
 * and translates it to the exact object that are loaded
 * in the musicChannelService for the blue selection
 * and calendarGroupService / calendarService for the green selection
 */

@Injectable({
  providedIn: 'root'
})
export class ActiveMusicSelectionService {

  private LOGGER_CLASSNAME = 'ActiveMusicSelectionService';

  constructor(
    private authenticationService: AuthenticationService,
    private musicSelectionService: MusicSelectionService,
    private musicChannelService: MusicChannelService,
    private calendarService: CalendarService,
    private calendarGroupService: CalendarGroupService,
    private loggerService: LoggerService
  ) {


    this.authenticationService.loggedIn$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        value => {
          if (value) {
            this._selectedMusicChannelLoading = true;
            this._selectedCalendarLoading = true;
          } else {
            this.clearData();
          }
        }
      );

    this.musicSelectionService.musicSelection$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (musicSelection) => {
          if (musicSelection) {
            //adjust other properties to this
            if (musicSelection.active === DTO_ActiveMode.MusicChannel) {
              this._selectedStreamMode = StreamMode.MUSIC_CHANNEL;
            } else if (musicSelection.active === DTO_ActiveMode.Calendar) {
              this._selectedStreamMode = StreamMode.CALENDAR;
            } else {
              this.loggerService.debug(this.LOGGER_CLASSNAME, "musicSelection setter", "activemode is " + musicSelection.active + " -> going to change to Music_channel");
              this._selectedStreamMode = StreamMode.MUSIC_CHANNEL;
            }
          }
          this.loadActiveMusicChannel();
          this.loadActiveCalendar();
        }
      );


    this.musicChannelService.musicChannelGroups$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        musicChannels => {
          this.loadActiveMusicChannel();
        }
      );

    this.calendarGroupService.calendarGroups$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        calendarGroups => {
          this.loadActiveCalendar();
        }
      );

    this.calendarService.customCalendars$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        calendars => {
          this.loadActiveCalendar();
        }
      );

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;

    this.selectedCalendarWillChange.next(); //cancel the subscription that watches an invlidate on the calendarItems
  }

  private clearData() {
    this._selectedMusicChannelSubject.next(null);
    this._selectedCalendarSubject.next(null);
    this.selectedCalendarWillChange.next(); //cancel the subscription that watches an invlidate on the calendarItems

    this._selectedCalendarLoading = false;
    this._selectedMusicChannelLoading = false;

    this._selectedStreamModeSubject.next(null);
  }




  /**
    * selected StreamMode (MusicChannel (blue) - Calendar (green))
    */
  private get _selectedStreamMode(): StreamMode {
    return this._selectedStreamModeSubject.value;
  }
  private set _selectedStreamMode(value: StreamMode) {
    if (this._selectedStreamMode !== value) {
      this._selectedStreamModeSubject.next(value);
    }
  }
  public get selectedStreamMode(): StreamMode {
    return this._selectedStreamMode;
  }
  private _selectedStreamModeSubject: BehaviorSubject<StreamMode> = new BehaviorSubject<StreamMode>(null);
  public selectedStreamMode$: Observable<StreamMode> = this._selectedStreamModeSubject.asObservable();


  //public method to change the selected stream mode, can be set from a UI component
  public set selectedStreamMode(value: StreamMode) {
    if (this._selectedStreamMode !== value) {
      this._selectedStreamMode = value;

      this.checkMusicSelectionChanged();
    }
  }



  /**
     * THE ACTIVE MUSIC CHANNEL
	 *
     * We play from this channel when our streamMode is MusicChannel
     */
  private get _selectedMusicChannel(): MusicChannel {
    return this._selectedMusicChannelSubject.value;
  }
  private set _selectedMusicChannel(value: MusicChannel) {
    if (this._selectedMusicChannel !== value) {
      this._selectedMusicChannelSubject.next(value);
    }
  }
  get selectedMusicChannel(): MusicChannel {
    return this._selectedMusicChannel;
  }
  private _selectedMusicChannelSubject: BehaviorSubject<MusicChannel> = new BehaviorSubject<MusicChannel>(null);
  public selectedMusicChannel$: Observable<MusicChannel> = this._selectedMusicChannelSubject.asObservable();


  //public method to change the active musicChannel, can be set from a UI component
  //this method triggers a save to the servers if needed
  public changeActiveMusicChannel(musicChannel: MusicChannel) {
    this._selectedMusicChannel = musicChannel;

    //this.loggerService.debug(this.LOGGER_CLASSNAME, "changeActiveMusicChannel", "changed to " + musicChannel.name);
    this.checkMusicSelectionChanged();
  }

  //property that indicates if the settings are loading (either musicSelection is loading or musicChannels are loading)
  private get _selectedMusicChannelLoading(): boolean {
    return this._selectedMusicChannelLoadingSubject.value;
  }
  private set _selectedMusicChannelLoading(value: boolean) {
    if (this._selectedMusicChannelLoading !== value) {
      this._selectedMusicChannelLoadingSubject.next(value);
    }
  }
  get selectedMusicChannelLoading(): boolean {
    return this._selectedMusicChannelLoading;
  }
  private _selectedMusicChannelLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public selectedMusicChannelLoading$: Observable<boolean> = this._selectedMusicChannelLoadingSubject.asObservable();

  //helper method to load the musicChannel object, based on the configured id and the loaded musicChannels
  private loadActiveMusicChannel() {
    if (this.musicSelectionService.musicSelection && this.musicChannelService.musicChannelGroups) {
      this._selectedMusicChannel = this.musicChannelService.findMusicChannelById(this.musicSelectionService.musicSelection.musicChannelId);
      this._selectedMusicChannelLoading = false;
    } else {
      this._selectedMusicChannelLoading = true;
    }
  }



  /**
     * THE ACTIVE CALENDAR
	 *
     * We play from this calendar when our streamMode is Calendar
     */
  private get _selectedCalendar(): Calendar {
    return this._selectedCalendarSubject.value;
  }
  private selectedCalendarWillChange = new Subject<void>();
  private set _selectedCalendar(value: Calendar) {
    if (this._selectedCalendar !== value) {
      this.selectedCalendarWillChange.next();
      this._selectedCalendarSubject.next(value);
      if (this._selectedCalendar != null){
        this._selectedCalendar.calendarItemsResetDoneEvent$
        .pipe(
          takeUntil(this.selectedCalendarWillChange)
        )
        .subscribe(
          () => {

            //TODO: check if we need this
            //this.calendarService.loadCalendarItems(this.selectedCalendar);

          }
        )
      }
    }
  }
  get selectedCalendar(): Calendar {
    return this._selectedCalendar;
  }
  private _selectedCalendarSubject: BehaviorSubject<Calendar> = new BehaviorSubject<Calendar>(null);
  public selectedCalendar$: Observable<Calendar> = this._selectedCalendarSubject.asObservable();

  //public method to change the active calendar, can be set from a UI component
  //this method triggers a save to the servers if needed
  public changeActiveCalendar(calendar: Calendar) {
    this._selectedCalendar = calendar;

    //this.loggerService.debug(this.LOGGER_CLASSNAME, "changeActiveCalendar", "changed to " + calendar.name);
    this.checkMusicSelectionChanged();
  }


  //property that indicates if the settings are loading (either musicSelection is loading or calendarGroups/customCalendars are loading)
  private get _selectedCalendarLoading(): boolean {
    return this._selectedCalendarLoadingSubject.value;
  }
  private set _selectedCalendarLoading(value: boolean) {
    if (this._selectedCalendarLoading !== value) {
      this._selectedCalendarLoadingSubject.next(value);
    }
  }
  get selectedCalendarLoading(): boolean {
    return this._selectedCalendarLoading;
  }
  private _selectedCalendarLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public selectedCalendarLoading$: Observable<boolean> = this._selectedCalendarLoadingSubject.asObservable();

  //helper method to load the calendar object, based on the configured id and the loaded calendars
  private loadActiveCalendar() {
    if (this.musicSelectionService.musicSelection && (this.calendarGroupService.calendarGroups || this.calendarService.customCalendars)) {
      if (this.musicSelectionService.musicSelection.calendarId && this.musicSelectionService.musicSelection.calendarId != 0) {
        const allLoaded = this.calendarGroupService.calendarGroups && (this.calendarService.customCalendars || !this.calendarService.customCalendarsAvailable);
        let newSelectedCalendar = null;
        if (this.calendarGroupService.calendarGroups) {
          //look if we can find the selected calendarId in the tunify calendars
          newSelectedCalendar = this.calendarGroupService.findCalendarById(this.musicSelectionService.musicSelection.calendarId);
        }
        if (newSelectedCalendar == null && this.calendarService.customCalendars) {
          newSelectedCalendar = this.calendarService.findCalendarById(this.musicSelectionService.musicSelection.calendarId);
        }
        if (newSelectedCalendar) {
          this._selectedCalendar = newSelectedCalendar;
          this._selectedCalendarLoading = false;
        } else {
          if (allLoaded) {
            //if the calendar was just removed -> it is ok
            if (this.selectedCalendar && this.selectedCalendar.deleted) {
              this.loggerService.error(this.LOGGER_CLASSNAME, "loadActiveCalendar", "calendar " + this.musicSelectionService.musicSelection.calendarId + " was just removed, keep selected");
            } else {
              this._selectedCalendar = null;
              this.loggerService.error(this.LOGGER_CLASSNAME, "loadActiveCalendar", "zone has a calendar configured with id " + this.musicSelectionService.musicSelection.calendarId + " but it is not found");
            }
          }
          this._selectedCalendarLoading = !allLoaded;
        }
      } else {
        this._selectedCalendar = null;
        this._selectedCalendarLoading = false;
      }
    } else {
      this._selectedCalendarLoading = true;
    }
  }


  /**
   * save musicSelection methods
   */
  //helper function that is triggered when one of the properties change
  //check if the musicSelection is changed and trigger a save if needed
  private checkMusicSelectionChanged() {
    //let composedMusicSelection = new MusicSelection();
    if (this.selectedStreamMode) {
      //Only check if the selected stream mode selection has changed
      if (this.selectedStreamMode === StreamMode.MUSIC_CHANNEL) {
        if (this.musicSelectionService.musicSelection === null || this.musicSelectionService.musicSelection.active !== DTO_ActiveMode.MusicChannel || (this.selectedMusicChannel && this.selectedMusicChannel.id !== this.musicSelectionService.musicSelection.musicChannelId)) {
          this.saveMusicSelection();
        }
      }
      if (this.selectedStreamMode === StreamMode.CALENDAR) {
        if (this.musicSelectionService.musicSelection === null
          || this.musicSelectionService.musicSelection.active !== DTO_ActiveMode.Calendar
          || (this.selectedCalendar
            && (this.selectedCalendar.calendarId != undefined && this.selectedCalendar.calendarId != 0) //ignore 'creating' calendars
            && this.selectedCalendar.calendarId !== this.musicSelectionService.musicSelection.calendarId)
        ) {
          this.saveMusicSelection();
        }
      }
    }
  }

  private saveMusicSelection() {
    //create a new musicSelection
    const musicSelection = new DTO_MusicSelection();
    musicSelection.active = this.selectedStreamMode === StreamMode.CALENDAR ? DTO_ActiveMode.Calendar : DTO_ActiveMode.MusicChannel;
    musicSelection.musicChannelId = this.selectedMusicChannel ? this.selectedMusicChannel.id : this.musicSelectionService.musicSelection ? this.musicSelectionService.musicSelection.musicChannelId : 0;
    musicSelection.calendarId = this.selectedCalendar ? this.selectedCalendar.calendarId : this.musicSelectionService.musicSelection ? this.musicSelectionService.musicSelection.calendarId : 0;

    this.musicSelectionService.saveMusicSelection(musicSelection);
  }

}



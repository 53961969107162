import { Component } from '@angular/core';

@Component({
  selector: 'tun-stream-icon-v5',
  templateUrl: './stream-icon-v5.component.html',
  styleUrl: './stream-icon-v5.component.scss'
})
export class StreamIconV5Component {

}

<div  #zoneConnectionsView (tunResize)="onResize($event)" class="main-container playerBackground">

  <div
    #zoneConnectionsContainer
    class="zone-connections-container"
  >

    <div class="scrollContainer">

        <div
          class="fade-borders"
          [style.height.px]="(heightPerItem$ | async)"
          [style.padding-top.px]="2 * (heightPerItem$ | async)"
        >
          <tun-tunify-icon></tun-tunify-icon>
        </div>

        <div class="errorFeedback fade-borders"
          *ngIf="(connectDesktopAppViewComponentStatus$ | async) == ConnectDesktopAppViewComponent_Status.NO_CONNECTIONS"
          fxLayout="column"
        >
          <label class="errorline" translate>desktopApp.connect.error.noConnections.info1</label>
          <label class="errorline" translate>desktopApp.connect.error.noConnections.info1</label>
          <button class="errorline link-button" (click)="onOpenMyTunify()">
            <p translate>
              desktopApp.connect.error.noConnections.openMytunify
            </p>
          </button>
        </div>

        <div class="status fade-borders">
          <tun-loader *ngIf="(connectDesktopAppViewComponentStatus$ | async) == ConnectDesktopAppViewComponent_Status.CHECKING" text="connecting to desktop app"></tun-loader>
        </div>

        <div class="errorFeedback fade-borders"
          *ngIf="(connectDesktopAppViewComponentStatus$ | async) == ConnectDesktopAppViewComponent_Status.NOT_RUNNING"
          fxLayout="column"
        >
          <label class="errorline" translate>desktopApp.connect.error.desktopAppNotFound.info1</label>
          <label class="errorline" translate>desktopApp.connect.error.desktopAppNotFound.info2</label>
        </div>

        <div
          class="errorFeedback fade-borders"
        >
          <!--<label *ngIf="(externalApplicationInfo$ | async) != null" translate>zoneConnections.inUse</label>-->

        </div>

        <div  class="flex-column">

          <div class="layerContainer zone-connections-layer-container">


            <div #scrollableZoneContainer class="layer scroll-container">

              <ng-container *ngIf="(connectDesktopAppViewComponentStatus$ | async) == ConnectDesktopAppViewComponent_Status.RUNNING">
                <div *ngFor="let zoneConnection of zoneConnections$ | async; let i = index;"
                  class="zone-connection-view {{i == 0 ? 'first':''}} {{i == (zoneConnections$ | async).length -1 ? 'last':''}}">

                  <tun-connect-zone-connection-to-desktop-view
                    [zoneConnection]="zoneConnection"
                  >
                  </tun-connect-zone-connection-to-desktop-view>

                </div>
              </ng-container>

            </div>


            <div
              *ngIf="(connectDesktopAppViewComponentStatus$ | async) == ConnectDesktopAppViewComponent_Status.RUNNING && (zoneConnections$ | async).length > 1"
              class="layer noPointer fade-borders"
            >
                <div style="float: left;">
                  <button
                  (click)="scrollLeft()"
                  class="button-transparent {{visibleZoneConnectionIndex > 0 ? '' : 'button-scroll-disabled'}}">
                  <fa-icon
                    class="button-item-icon"
                    size="lg"
                    [icon]="faAngleLeft"
                  ></fa-icon>
                  </button>
                </div>
                <div style="float: right;">
                  <button
                  (click)="scrollRight()"
                  class="button-transparent {{visibleZoneConnectionIndex < (zoneConnections$ | async).length - 1 ? '' : 'button-scroll-disabled'}}">
                  <fa-icon
                    class="button-item-icon"
                    size="lg"
                    [icon]="faAngleRight"
                  ></fa-icon>
                </button>
                </div>


            </div>
          </div>


        </div>

        <div *ngIf="sharedOne$ | async" class="flex-column text-container fade-borders" translate>
          desktopApp.connect.done
        </div>

        <div class="flex-column fill fade-borders"></div>

      </div>
    </div>
</div>




import { ImageHolder, ImageInfo } from './imageInfo';
import { MusicCollection } from './musicCollection';
import { createClassObjectForMusicCollection } from '@service/util/musicCollectionUtil';

export class MusicChannel implements ImageHolder{
    id: number;
    name: string;
    imageInfo: ImageInfo;
    musicChannelGroupId: number;
    musicCollections: MusicCollection[];

    constructor(musicChannel?: MusicChannel){
        if (musicChannel){
            this.id = musicChannel.id;
            this.name = musicChannel.name;

            this.musicChannelGroupId = musicChannel.musicChannelGroupId;
            this.musicCollections = [];
            if (musicChannel.musicCollections){
                musicChannel.musicCollections.forEach(musicCollection => {
                    this.musicCollections.push(createClassObjectForMusicCollection(musicCollection));
                });
            }

            if (musicChannel.imageInfo){
              this.imageInfo = new ImageInfo(musicChannel.imageInfo);
            }
        }
    }
}

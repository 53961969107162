import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Calendar } from '@model/calendar';
import { StreamMode } from '@model/enums/streamMode';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';
import { ImageHolder } from '@model/imageInfo';
import { MusicChannel } from '@model/musicChannel';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { PlayingItemService } from '@service/app-v5/playing-item.service';
import { PlayingMusicSelectionService, PlayingOrigin } from '@service/app-v5/playing-music-selection.service';
import { combineLatest, map, Subject } from 'rxjs';

export enum PlayButtonShowStrategy {
  ALWAYS = 'ALWAYS',
  NEVER = 'NEVER',
  ON_HOVER = 'ON_HOVER'
}

@Component({
  template: ''
})
export class ImageHolderViewComponent<Type extends ImageHolder> implements OnDestroy{

  @Input() imageHolder: Type;
  @Input() recommendationsAmount: number = 0;
  @Input() playButtonShowStragegy: PlayButtonShowStrategy = PlayButtonShowStrategy.NEVER;

  @Output() showDetails = new EventEmitter<Type>();

  public PlayButtonShowStrategy = PlayButtonShowStrategy;

  public get startableItem(): MusicChannel | Calendar{
    if (this.imageHolder instanceof MusicChannel || this.imageHolder instanceof Calendar){
      return this.imageHolder;
    }
  }

  public playAnimationColors : MusicPlayAnimationColors

  public get forceShowTogglePlayButton$(){
    return this.isActive$;
  }

  public get isActive$(){
    return combineLatest([this.activeMusicSelectionService.selectedStreamMode$, this.activeMusicSelectionService.selectedMusicChannel$, this.activeMusicSelectionService.selectedCalendar$])
      .pipe(
        map(([streamMode, selectedMusicChannel, selectedCalendar]) => {
          if (this.imageHolder instanceof MusicChannel){
            return streamMode == StreamMode.MUSIC_CHANNEL && selectedMusicChannel == this.imageHolder
          }else if (this.imageHolder instanceof Calendar){
            return streamMode == StreamMode.CALENDAR && selectedCalendar == this.imageHolder
          }
        })
      )
  }

  public get showPlayAnimation$(){
    return this.playingMusicSelectionService.playingMusicSelection$
      .pipe(
        map(playingMusicSelection => {
          if (this.imageHolder instanceof MusicChannel){
            return playingMusicSelection.origin == PlayingOrigin.musicChannel && playingMusicSelection.musicChannel == this.imageHolder
          }else if (this.imageHolder instanceof Calendar){
            return playingMusicSelection.origin == PlayingOrigin.calendar && playingMusicSelection.calendar == this.imageHolder
          }

        })
      )
  }

  public get animatePlayAnimation$(){
    return this.playingItemService.isPlaying$
  }

  public get imageUrl(){
    if (this.imageHolder && this.imageHolder.imageInfo){
      return this.imageHolder.imageInfo.urlWebSquare
    }
    return null;
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.mouseOver = true;
    console.log('mouse over');
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.mouseOver = false;
    console.log('mouse leave');
  }

  private _mouseOver = false;
  private set mouseOver(value: boolean){
    this._mouseOver = value;
  }
  public get mouseOver(){
    return this._mouseOver;
  }

  constructor(
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private playingMusicSelectionService: PlayingMusicSelectionService,
    private playingItemService: PlayingItemService,
    ) {

      this.playAnimationColors = new MusicPlayAnimationColors()
          .selectedColor('#8E8E93')
          .unselectedColor('#8E8E93');

    }

    private destroyed$ = new Subject<void>();
    ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
      this.destroyed$ = null;
    }

    public onShowDetails(){
      this.showDetails.emit(this.imageHolder);
    }

    //We could watch the musicChannel and show the details when it is starting
    public onItemIsStarting(){

    }
}


@Component({
  selector: "tun-music-channel-view",
  templateUrl: "./image-holder-view.component.html",
  styleUrls: ["./image-holder-view.component.scss"],
})
export class MusicChannelFromImageHolderViewComponent extends ImageHolderViewComponent<MusicChannel> implements OnDestroy{}

@Component({
  selector: "tun-calendar-view",
  templateUrl: "./image-holder-view.component.html",
  styleUrls: ["./image-holder-view.component.scss"],
})
export class CalendarFromImageHolderViewComponent extends ImageHolderViewComponent<Calendar> implements OnDestroy{}


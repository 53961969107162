<div class="wrapper">

  <button *ngIf="enableHome"  class="tab-button tab-home {{(selectedTab$ | async) == TunifyV5Tab.HOME ? 'selected' : ''}}" (click)="onToggleTab(TunifyV5Tab.HOME)">
    <tun-menu-home-icon-v5 class="icon icon-scaled-svg-button icon-tab-button"></tun-menu-home-icon-v5 > {{'menu.tab.home' | translate}}
  </button>

  <button class="tab-button tab-music-channel {{(selectedTab$ | async) == TunifyV5Tab.MUSIC_CHANNEL ? 'selected' : ''}}" (click)="onToggleTab(TunifyV5Tab.MUSIC_CHANNEL)">
    <tun-stream-icon-v5  class="icon icon-scaled-svg-button icon-tab-button"></tun-stream-icon-v5> {{'musicChannel.tab.label' | translate}}
  </button>

  <button class="tab-button tab-calendar {{(selectedTab$ | async) == TunifyV5Tab.CALENDAR ? 'selected' : ''}}" (click)="onToggleTab(TunifyV5Tab.CALENDAR)">
    <tun-menu-calendar-icon-v5 class="icon icon-scaled-svg-button icon-tab-button"></tun-menu-calendar-icon-v5> {{'calendars.tab.label' | translate}}
  </button>

  <button class="tab-button tab-search {{(selectedTab$ | async) == TunifyV5Tab.SEARCH ? 'selected' : ''}}" (click)="onToggleTab(TunifyV5Tab.SEARCH)">
    <tun-menu-search-icon-v5 class="icon icon-scaled-svg-button icon-tab-button"></tun-menu-search-icon-v5> {{'search.tab.label' | translate}}
  </button>

  <button class="tab-button tab-playlist {{(selectedTab$ | async) == TunifyV5Tab.PLAYLIST ? 'selected' : ''}}" (click)="onToggleTab(TunifyV5Tab.PLAYLIST)">
    <tun-menu-playlist-icon-v5 class="icon icon-scaled-svg-button icon-tab-button"></tun-menu-playlist-icon-v5> {{'playlist.tab.label' | translate}}
  </button>

  <button class="tab-button tab-settings {{(selectedTab$ | async) == TunifyV5Tab.SETTINGS ? 'selected' : ''}}" (click)="onToggleTab(TunifyV5Tab.SETTINGS)">
    <tun-menu-settings-icon-v5 class="icon icon-scaled-svg-button icon-tab-button"></tun-menu-settings-icon-v5> {{'settings.tab.label' | translate}}
  </button>

</div>
